/* display flexs' */
.main,
.url-submit-form,
.url-received-container,
.copy-link-btn-wrapper {
  display: flex;
}

.main {
  height: 100vh;
  width: 100%;
  background: url("https://cutewallpaper.org/28/dedsec-phone-wallpaper-gif/dedsec-2-on-make-a-gif.gif");
  /* background: url('https://media.tenor.com/FcA59Dz2qf0AAAAd/watch-dogs2-dedsec.gif'); */
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
}

.url-submit-form,
.url-received-container {
  height: 30rem;
  width: 22rem;
  background: white;
  margin-left: 5rem;
  border-radius: 1rem;
  overflow: auto;
  flex-direction: column;
  justify-content: space-between;
}

/* form submit button  */
.form-submit-btn-wrapper {
  padding: 2rem;
}

.form-submit-btn-wrapper,
.copy-link-btn-wrapper {
  text-align: center;
}

.form-submit-btn-wrapper > button,
.copy-link-btn-wrapper > button {
  padding: 0.85rem 2rem;
  border-radius: 2rem;
  background: #a459d1;
  color: white;
}

.form-submit-btn-wrapper > button:hover,
.copy-link-btn-wrapper > button:hover {
  background: #7a429b;
}

/* short url received container  */
.url-received-container {
  align-items: center;
  justify-content: space-evenly;
}

.link-ready-logo {
  width: 8rem;
  height: 8rem;
  background: url("/public/assets/hacker.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.copy-link-btn-wrapper {
  padding: 1rem;
  flex-direction: column;
  gap: 0.5rem;
}

.copy-link-btn-wrapper > a {
  color: rgba(122, 122, 122, 0.8);
  font-size: 0.85rem;
  text-decoration: underline;
}
